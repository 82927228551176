import React from "react";

import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import FeedbackIcon from '@material-ui/icons/Feedback';
import HelpIcon from '@material-ui/icons/Help';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import SettingsIcon from '@material-ui/icons/Settings';
import LanguageIcon from '@material-ui/icons/Language';
import TranslateIcon from '@material-ui/icons/Translate';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const renderSwitch = (param) => {
  switch (param) {
    case 'AccountBoxIcon':
      return <AccountBoxIcon />;
    case 'MonetizationOnIcon':
      return <MonetizationOnIcon />;
    case 'KeyboardIcon':
      return <KeyboardIcon />;
    case 'FeedbackIcon':
      return <FeedbackIcon />;
    case 'HelpIcon':
      return <HelpIcon />;
    case 'AssignmentIndIcon':
      return <AssignmentIndIcon />;
    case 'SettingsIcon':
      return <SettingsIcon />;
    case 'LanguageIcon':
      return <LanguageIcon />;
    case 'TranslateIcon':
      return <TranslateIcon />;
    case 'ArrowForwardIosIcon':
      return <ArrowForwardIosIcon style={{ fontSize: "15px" }} />;
    case 'SupervisorAccountIcon':
      return <SupervisorAccountIcon />;
    case 'ExitToAppIcon':
      return <ExitToAppIcon />;
    default:
      return null;
  }
}

function Icon({ iconName }) {
  return (
    <>
      {renderSwitch(iconName)}
    </>
  )
}


export default Icon;
