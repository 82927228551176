import React from 'react';
import './SidebarRow.css';
import { useStateValue } from './StateProvider';

function SidebarRow({ selected, Icon, title }) {

  const [{ darkmode }] = useStateValue();

  return (
    <div className={`sidebarRow ${selected && " selected "} ${darkmode && "sidebarRow-dark"}`}>
      <Icon className={`sidebarRow-icon ${darkmode} sidebarRow-icon-dark`} />
      <h2 className={`sidebarRow-title ${darkmode && "sidebarRow-title-dark"}`}>{title}</h2>
    </div>
  )
}

export default SidebarRow
