export const initialState = {
  darkmode: false,
  user: null,
  search: false
}


const reducer = (state, action) => {
  switch (action.type) {

    case 'DARK_MODE_ON':
      return {
        ...state,
        darkmode: true,
      };
    case 'DARK_MODE_OFF':
      return {
        ...state,
        darkmode: false,
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.user
      };
    case 'SEARCH_SHOW':
      return {
        ...state,
        search: true
      };
    case 'SEARCH_NOT_SHOW':
      return {
        ...state,
        search: false
      };
    default:
      return state;
  }
}

export default reducer;