import React from 'react';
import './HeaderOptions.css';
import { useStateValue } from './StateProvider';
import Icon from './Icons';

function HeaderOptions({ icon, text, right }) {

  const [{ darkmode }] = useStateValue();

  return (
    <div className="header-optionsIcon">
      <div className="header-optionsIcon-item">
        <Icon iconName={icon} />
      </div>
      <p className={`header-optionsIconText ${darkmode && "header-optionsIconText-dark"}`}>
        {text}
      </p>
      {right ? (
        <div className={`header-optionsIcon-itemArrow ${darkmode && "headerOptions-icon-dark"}`}>
          <Icon iconName="ArrowForwardIosIcon" />
        </div>
      ) : (null)}
    </div>
  )
}

export default HeaderOptions;
