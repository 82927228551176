import React, { useEffect } from 'react';
import './App.css';
import Header from './Header';
import RecommendedVideos from './RecommendedVideos';
import Sidebar from './Sidebar';
import { useStateValue } from './StateProvider';
import HeaderSearch from './HeaderSearch';
import { useMediaQuery } from 'react-responsive';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import SearchPage from './SearchPage';

function App() {

  const [{ darkmode }] = useStateValue();
  const [{ search }, dispatch] = useStateValue();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 599px)' })

  useEffect(() => {
    isSmallScreen ?
      dispatch({
        type: 'SEARCH_NOT_SHOW',
      })
      :
      dispatch({
        type: 'SEARCH_SHOW',
      })
  }, [isSmallScreen, dispatch])

  return (
    <div className="app">
      {
        isSmallScreen ?
          (
            <Router>
              <Switch>
                <Route path="/search/:searchTerm">
                  {!search ? (
                    <Header />
                  ) : (
                      <HeaderSearch />
                    )}
                  <div className={`app-page ${darkmode && 'app-page-dark'}`}>
                    <SearchPage />
                  </div>
                </Route>
                <Route path="/">
                  {
                    !search ? (
                      <Header />
                    ) : (
                        <HeaderSearch />
                      )}
                  <div className={`app-page ${darkmode && 'app-page-dark'}`}>
                    <RecommendedVideos />
                  </div>
                </Route>
              </Switch>
            </Router>
          ) : (
            <Router>
              <Switch>
                <Route path="/search/:searchTerm">
                  <Header />
                  <div className={`app-page ${darkmode && 'app-page-dark'}`}>
                    <Sidebar />
                    <SearchPage />
                  </div>

                </Route>
                <Route path="/">
                  <Header />
                  <div className={`app-page ${darkmode && 'app-page-dark'}`}>
                    <Sidebar />
                    <RecommendedVideos />
                  </div>
                </Route>
              </Switch>
            </Router>
          )
      }
    </div>
  );
}

export default App;
