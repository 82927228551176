import React from 'react';
import './SearchPage.css';
import { useStateValue } from './StateProvider';
import { useMediaQuery } from 'react-responsive';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import ChannelRow from './ChannelRow';
import VideoRow from './VideoRow';

function SearchPage() {

  const [{ darkmode }] = useStateValue();
  const [{ user }] = useStateValue();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 599px)' })


  return (
    <div className={`${isSmallScreen ? 'searchPageSmall' : 'searchPage'} ${darkmode && 'searchPage-dark'}`}>

      <div className={`searchPage-filter ${darkmode && "searchPage-filter-dark"}`}>
        <TuneOutlinedIcon />
        <h2>FILTER</h2>
      </div>
      <hr />

      <ChannelRow
        image={user ? user.photoURL : "https://pga-tour-res.cloudinary.com/image/upload/c_fill,d_headshots_default.png,dpr_3.0,f_auto,g_face:center,h_350,q_auto,w_280/headshots_20112.png"}
        channel={user ? user.displayName : "John Doe"}
        verified
        subs="659K"
        noOfVideos={286}
        description="You can find all technical knowledges here with some awesome programming here Please be sure to subscribe me and get notified about my video"
      />
      <hr />

      <VideoRow
        views="2.3M Views"
        subs="659K"
        description="You can learn here how to create websites for free and host them for free under minutes. Now you can be an absolute beast wb developer in 10 minutes."
        timestamp="3 days ago"
        channel="Sonny Sangha"
        title="Become a Web Developer in 10 minutes | 2019/2020"
        image="https://i.ytimg.com/vi/UaB_FtsdlYk/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAuLlFdsy7dY2-7Ja9yu3yOIHuZEQ"
        channel_image="https://yt3.ggpht.com/ytc/AAUvwnj0RZ4V5xQUonkbHHFEMa50ZIcQ5uCz0PEA-VU5naY=s68-c-k-c0x00ffffff-no-rj"
      />

      <VideoRow
        views="87K views"
        subs="299K"
        description="You can learn here how to create websites for free and host them for free under minutes. Now you can be an absolute beast wb developer in 10 minutes."
        timestamp="9 months ago"
        channel="Dev Ed"
        title="I Design a Website In 10 Minutes"
        image="https://i.ytimg.com/vi/9o-u6e1mTlM/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDKpE7VnXn_plFjUfqg21JRLTaROQ"
        channel_image="https://yt3.ggpht.com/ytc/AAUvwnjI1e_kjzToMdbPML1lfLgJxlArNmMbklUQYDsJ2Q=s68-c-k-c0x00ffffff-no-rj" />

      <VideoRow
        views="195K views"
        subs="400K"
        description="A look in inside apple's $5 billion worth headquaters."
        timestamp="6 days ago"
        channel="Apple Explained"
        title="Inside Apple's $5 Billion Headquarters"
        image="https://i.ytimg.com/vi/T9dJ_cE5Asw/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB4VYvk8T391uvCZgEfFg62tuAVDQ"
        channel_image="https://yt3.ggpht.com/ytc/AAUvwnhKlXIUI8Yw8kCCkvzsPMiw9kKUBY36QgcEV2DIoA=s68-c-k-c0x00ffffff-no-rj" />

      <VideoRow
        views="192K Views"
        subs="2.3M"
        description="Does Bayron mode destroys Naruto and saves Kawaki as well as the world watch this video to know more and subscribe to my channel to know more about Naruto world."
        timestamp="1 month ago"
        channel="AnimeUproar"
        title="Baryon Mode Naruto vs Isshiki Conclusion! Boruto Momoshiki RETURNS with TRAGIC Results!"
        image="https://i.ytimg.com/vi/xO9dLqFQVIo/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA9IJaF76vXHW_UWZxEC58pCMmeRA"
        channel_image="https://yt3.ggpht.com/ytc/AAUvwniOJb2R54kO1453Z-7RAYFdBQug8KPiJzXqwldbmw=s68-c-k-c0x00ffffff-no-rj" />

      <VideoRow
        views="202M Views"
        subs="3B"
        description="This song is close to my heart and describes the feeling of many in words."
        timestamp="3 years ago"
        channel="Camila Cabello"
        title="Camila Cabello - Crying in the Club"
        image="https://i.ytimg.com/vi/NlrYn_dZdqk/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCPR7uIipAzF_OtS9QEZA2Q_ws8fw"
        channel_image="https://yt3.ggpht.com/ytc/AAUvwnguPU2HS7cZI7DM2fG9lA8ZdkZnjTGcWu9vuTRlug=s88-c-k-c0x00ffffff-no-r" />

      <VideoRow
        views="329K Views"
        subs="100K"
        description="Though they have drifted apart in footballing world Enjoy the best trio in action !!!! Benzama, Bale and Cristiano."
        timestamp="4 years ago"
        channel="Rafa Cambón"
        title="Real Madrid BBC (Best Goals)"
        image="https://i.ytimg.com/vi/l5mlxWWXEmY/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA-OnmOmholt6rQ7cc-PakUFovpcQ"
        channel_image="https://yt3.ggpht.com/ytc/AAUvwnjZtBnfS8H2s4qhkby9NgSDyQQ4AQs6BJVhQdvoCEg=s68-c-k-c0x00ffffff-no-rj" />
      <VideoRow
        views="209M Views"
        subs="922M"
        description="This is a diss track towards a certain someone so please don't go out of your way to hate them, this is just for entertaintment purposes. "
        timestamp="8 months ago"
        channel="CarryMinati"
        title="YALGAAR - CARRYMINATI X Wily Frenzy"
        image="https://i.ytimg.com/vi/zzwRbKI2pn4/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDPGrPWb9itIsnYQOR562W9nqdygg"
        channel_image="https://yt3.ggpht.com/ytc/AAUvwnh5ezpU_2OC0A4F9XNx10JOSRXCH3GfMhLwy8klNg=s68-c-k-c0x00ffffff-no-rj" />

      <VideoRow
        views="285K Views"
        subs="101K"
        description="This is by no means an official channel The videos are just for fun purposes and light masti."
        timestamp="3 weeks ago"
        channel="Taarak Mehta Ka Ooltah Chashmah Episodes"
        title="Taarak Mehta Ka Ooltah Chashmah - Episode 882 - Full Episode"
        image="https://i.ytimg.com/vi/4u_uAye5svE/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC5r-L-_8Ds76FetQVnrBXrkozPzQ"
        channel_image="https://yt3.ggpht.com/ytc/AAUvwngKK7MGHpG6pciVmyexCE8wtCJsoQ2mHmx-R0GS=s68-c-k-c0x00ffffff-no-rj" />
      <VideoRow
        views="987M Views"
        subs="99K"
        description="Enjoy the fastest hattrick cutless and without any interruption. Enjoy all the three goals from Las Galacticas Kosovare Asllani in just 155 seconds, a world record."
        timestamp="1 week ago"
        channel="Real Madrid"
        title="Asllani's three goals in just 155 seconds"
        image="https://i.ytimg.com/vi/0Hanu4hLN4A/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBNI07q5_x7oVIVEj89mWEWditDtw"
        channel_image="https://yt3.ggpht.com/ytc/AAUvwnjZtBnfS8H2s4qhkby9NgSDyQQ4AQs6BJVhQdvoCEg=s68-c-k-c0x00ffffff-no-rj" />

      <VideoRow
        views="329M Views"
        subs="33K"
        description="Enjoy an amalgamation of best characters from around the world. Its Marvel vs DC, like if you enjoy!!"
        timestamp="5 years ago"
        channel="Mightyraccoon!"
        title="Marvel vs. DC - The Ultimate Crossover (Complete Story) | Animation Film"
        image="https://i.ytimg.com/vi/Xgp6NSUhLuo/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDZp-nrTmB_PRaV9GrJOdCbTIlO9w"
        channel_image="https://yt3.ggpht.com/ytc/AAUvwniImmhRFi3iRer9aWBk7nyWQm1KRIDIVD-VS4vs3w=s68-c-k-c0x00ffffff-no-rj"
      />

    </div>
  )
}

export default SearchPage
