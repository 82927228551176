import { Avatar } from '@material-ui/core';
import React from 'react';
import { useStateValue } from './StateProvider';
import './VideoRow.css';

function VideoRow({ views, subs, description, timestamp, channel, title, image, channel_image }) {

  const [{ darkmode }] = useStateValue();

  return (
    <div className="videoRow">
      <img src={image} alt={channel} />
      <div className={`videoRow-text ${darkmode && "videoRow-text-dark"}`}>
        <h3>{title}</h3>
        <p className={`videoRow-headline ${darkmode && "videoRow-headline-dark"}`}>
          <span className="videoRow-subsNumber">
            {views}
          </span>
          <span className="videoRow-dot">
            &bull;
            </span>
          {timestamp}
        </p>
        <div className={`videoRow-channelDetils ${darkmode && "videoRow-channelDetils-dark"}`}>
          <Avatar className="videoCard-avatar" alt={channel} src={channel_image} />
          <p>{channel}</p>
        </div>
        <p className={`videoRow-description ${darkmode && "videoRow-description-dark"}`}>{description}</p>
      </div>
    </div>
  )
}

export default VideoRow
