import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyB5OV_LUg2QmFkEnZ8O5FH7Hki_XF4ZtLE",
  authDomain: "clone-50a7c.firebaseapp.com",
  projectId: "clone-50a7c",
  storageBucket: "clone-50a7c.appspot.com",
  messagingSenderId: "152885489414",
  appId: "1:152885489414:web:0facd739c98144dd38f887",
  measurementId: "G-22D588JK39"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const database = firebaseApp.firestore();
const auth = firebaseApp.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export { auth, provider }
export default database;