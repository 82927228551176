import React from 'react';
import './RecommendedVideos.css';
import VideoCard from './VideoCard';
import { useStateValue } from './StateProvider';
import { useMediaQuery } from 'react-responsive';

function RecommendedVideos() {

  const [{ darkmode }] = useStateValue();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 599px)' })

  return (
    <div className={` ${isSmallScreen ? 'recommendedVideosSmall' : 'recommendedVideos'} ${darkmode && 'recommendedVideos-dark'}`}>
      <h2>Recommended</h2>
      <div className={`recommendedVideos-videos ${darkmode && "recommendedVideos-videos-dark"}`}>
        <VideoCard
          image="https://i.ytimg.com/vi/UaB_FtsdlYk/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAuLlFdsy7dY2-7Ja9yu3yOIHuZEQ"
          title="Become a Web Developer in 10 minutes | 2019/2020"
          channel="Sonny Sangha"
          views="2.3M Views"
          timestamp="3 days ago"
          channel_image="https://yt3.ggpht.com/ytc/AAUvwnj0RZ4V5xQUonkbHHFEMa50ZIcQ5uCz0PEA-VU5naY=s68-c-k-c0x00ffffff-no-rj" />

        <VideoCard
          image="https://i.ytimg.com/vi/9o-u6e1mTlM/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDKpE7VnXn_plFjUfqg21JRLTaROQ"
          title="I Design a Website In 10 Minutes"
          channel="Dev Ed"
          views="87K views"
          timestamp="9 months ago"
          channel_image="https://yt3.ggpht.com/ytc/AAUvwnjI1e_kjzToMdbPML1lfLgJxlArNmMbklUQYDsJ2Q=s68-c-k-c0x00ffffff-no-rj" />

        <VideoCard
          image="https://i.ytimg.com/vi/T9dJ_cE5Asw/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB4VYvk8T391uvCZgEfFg62tuAVDQ"
          title="Inside Apple's $5 Billion Headquarters"
          channel="Apple Explained"
          views="195K views"
          timestamp="6 days ago"
          channel_image="https://yt3.ggpht.com/ytc/AAUvwnhKlXIUI8Yw8kCCkvzsPMiw9kKUBY36QgcEV2DIoA=s68-c-k-c0x00ffffff-no-rj" />

        <VideoCard
          image="https://i.ytimg.com/vi/xO9dLqFQVIo/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA9IJaF76vXHW_UWZxEC58pCMmeRA"
          title="Baryon Mode Naruto vs Isshiki Conclusion! Boruto Momoshiki RETURNS with TRAGIC Results!"
          channel="AnimeUproar"
          views="192K Views"
          timestamp="1 month ago"
          channel_image="https://yt3.ggpht.com/ytc/AAUvwniOJb2R54kO1453Z-7RAYFdBQug8KPiJzXqwldbmw=s68-c-k-c0x00ffffff-no-rj" />

        <VideoCard
          image="https://i.ytimg.com/vi/NlrYn_dZdqk/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCPR7uIipAzF_OtS9QEZA2Q_ws8fw"
          title="Camila Cabello - Crying in the Club"
          channel="Camila Cabello"
          views="202M Views"
          timestamp="3 years ago"
          channel_image="https://yt3.ggpht.com/ytc/AAUvwnguPU2HS7cZI7DM2fG9lA8ZdkZnjTGcWu9vuTRlug=s88-c-k-c0x00ffffff-no-rj" />

        <VideoCard
          image="https://i.ytimg.com/vi/l5mlxWWXEmY/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA-OnmOmholt6rQ7cc-PakUFovpcQ"
          title="Real Madrid BBC (Best Goals)"
          channel="Rafa Cambón"
          views="329K Views"
          timestamp="4 years ago"
          channel_image="https://yt3.ggpht.com/ytc/AAUvwnjZtBnfS8H2s4qhkby9NgSDyQQ4AQs6BJVhQdvoCEg=s68-c-k-c0x00ffffff-no-rj" />
        <VideoCard
          image="https://i.ytimg.com/vi/zzwRbKI2pn4/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDPGrPWb9itIsnYQOR562W9nqdygg"
          title="YALGAAR - CARRYMINATI X Wily Frenzy"
          channel="CarryMinati"
          views="209M Views"
          timestamp="8 months ago"
          channel_image="https://yt3.ggpht.com/ytc/AAUvwnh5ezpU_2OC0A4F9XNx10JOSRXCH3GfMhLwy8klNg=s68-c-k-c0x00ffffff-no-rj" />

        <VideoCard
          image="https://i.ytimg.com/vi/4u_uAye5svE/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC5r-L-_8Ds76FetQVnrBXrkozPzQ"
          title="Taarak Mehta Ka Ooltah Chashmah - Episode 882 - Full Episode"
          channel="Taarak Mehta Ka Ooltah Chashmah Episodes"
          views="285K Views"
          timestamp="3 weeks ago"
          channel_image="https://yt3.ggpht.com/ytc/AAUvwngKK7MGHpG6pciVmyexCE8wtCJsoQ2mHmx-R0GS=s68-c-k-c0x00ffffff-no-rj" />
        <VideoCard
          image="https://i.ytimg.com/vi/0Hanu4hLN4A/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBNI07q5_x7oVIVEj89mWEWditDtw"
          title="Asllani's three goals in just 155 seconds"
          channel="Real Madrid"
          views="987M Views"
          timestamp="1 week ago"
          channel_image="https://yt3.ggpht.com/ytc/AAUvwnjZtBnfS8H2s4qhkby9NgSDyQQ4AQs6BJVhQdvoCEg=s68-c-k-c0x00ffffff-no-rj" />

        <VideoCard
          image="https://i.ytimg.com/vi/Xgp6NSUhLuo/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDZp-nrTmB_PRaV9GrJOdCbTIlO9w"
          title="Marvel vs. DC - The Ultimate Crossover (Complete Story) | Animation Film"
          channel="Mightyraccoon!"
          views="329M Views"
          timestamp="5 years ago"
          channel_image="https://yt3.ggpht.com/ytc/AAUvwniImmhRFi3iRer9aWBk7nyWQm1KRIDIVD-VS4vs3w=s68-c-k-c0x00ffffff-no-rj" />
      </div>
    </div>
  )
}

export default RecommendedVideos
