import React, { useEffect, useState } from 'react';
import './Header.css';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import AppsIcon from '@material-ui/icons/Apps';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Avatar, Button, IconButton } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MicIcon from '@material-ui/icons/Mic';
import { useStateValue } from './StateProvider';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { auth, provider } from './firebase';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import Popper from './Popper';


function Header() {
  const [{ user }] = useStateValue();
  const [{ search }] = useStateValue();
  const [{ darkmode }, dispatch] = useStateValue();
  const [anchorEl, setAnchorEl] = useState(null);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 599px)' })
  const [inputSearch, setInputSearch] = useState('');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // User is logged in
        dispatch({
          type: 'SET_USER',
          user: authUser
        })
        console.log(authUser)
      }
      else {
        // User is not logged in
        dispatch({
          type: 'SET_USER',
          user: null
        })
      }
    })

    return () => {
      // cleanup
      unsubscribe();
    }
  }, [dispatch])
  const signIn = (e) => {
    e.preventDefault();
    auth.signInWithPopup(provider)
      .then((auth) => {
        console.log(auth)
      })
      .catch((error) => console.log(error.message));
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleSearch = () => {
    search ?
      dispatch({
        type: 'SEARCH_NOT_SHOW',
      })
      :
      dispatch({
        type: 'SEARCH_SHOW',
      })
  }

  return (
    <div className={`header ${darkmode && "header-dark"}`} >
      <div className="header-left">
        <MenuIcon className={`${darkmode && "header-icon-dark"}`} />
        <Link to="/">
          {
            darkmode ? (
              <img
                className="header-logo"
                src={isSmallScreen ? "https://www.youtube.com/about/static/svgs/icons/brand-resources/YouTube_icon_full-color.svg?cache=f2ec7a5" : "https://www.youtube.com/about/static/svgs/icons/brand-resources/YouTube-logo-full_color_dark.svg?cache=bb9b9c6"}
                alt="YouTube Icon" />
            ) : (
                <img
                  className="header-logo"
                  src={isSmallScreen ? "https://www.youtube.com/about/static/svgs/icons/brand-resources/YouTube_icon_full-color.svg?cache=f2ec7a5" : "https://upload.wikimedia.org/wikipedia/commons/b/b8/YouTube_Logo_2017.svg"}
                  alt="YouTube Icon" />
              )
          }
        </Link>
      </div>
      {
        !isSmallScreen ? (
          <div className={`header-inputContainer ${darkmode && "header-inputContainer-dark"}`}>
            <div className={`header-input ${darkmode && "header-input-dark"}`}>
              <input value={inputSearch} onChange={e => setInputSearch(e.target.value)} placeholder="Search" type="text" />
              <Link to={`/search/${inputSearch}`}>
                <div className="header-input-searchLink-button">
                  <SearchIcon className={`header-searchInputButton ${darkmode && "header-searchInputButton-dark"}`} />
                </div>
              </Link>
            </div>
            <IconButton className="header-micInputButtonContainer">
              <MicIcon className={`header-micInputButton ${darkmode && "header-micInputButton-dark"}`} />
            </IconButton>
          </div>
        ) : (
            <div className="">
              <IconButton className="header-micInputButtonContainer">
                <MicIcon className={`header-micInputButton ${darkmode && "header-micInputButton-dark"}`} />
              </IconButton>
              <Link to={`/search/${inputSearch}`}>
                <IconButton className="header-input-searchLink-button" onClick={() => toggleSearch()}>
                  <SearchIcon className={`header-small-searchInputButton ${darkmode && "header-small-searchInputButton-dark"}`} />
                </IconButton>
              </Link>
            </div>
          )
      }

      <div className="header-icons">
        {
          user ? (
            <VideoCallIcon className={`header-icon ${darkmode && "header-icon-dark"}`} />
          ) : (
              null
            )
        }
        <AppsIcon className={`header-icon ${darkmode && "header-icon-dark"}`} />
        {
          user ? (
            <NotificationsIcon className={`header-icon ${darkmode && "header-icon-dark"}`} />
          ) : (
              null
            )
        }


        {
          user ? (
            <div className="">
              <Avatar onClick={handleClick} alt={user.displayName} src={user.photoURL} />
              <div className="options-popover">
                <Popper handleClose={handleClose} anchorEl={anchorEl} userIsPresent={true} />
              </div>
            </div>

          ) :
            (
              <div style={{ display: "flex", alignItems: 'center' }} className="">
                <IconButton onClick={handleClick}>
                  <MoreVertIcon className={`header-icon ${darkmode && "header-icon-dark"}`} />
                </IconButton>
                <div className="options-popover">
                  <Popper handleClose={handleClose} anchorEl={anchorEl} userIsPresent={false} />
                </div>
                <div className={`header-signInButton ${darkmode && "header-signInButton-dark"}`}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={signIn}
                    className=""
                    startIcon={<AccountCircleIcon className={`header-signInButton-icon ${darkmode && "header-signInButton-icon-dark"}`} />}
                  >
                    Sign In
          </Button>
                </div>
              </div>
            )
        }
      </div>
    </div>
  )
}

export default Header
