import { Avatar } from '@material-ui/core';
import React from 'react';
import './ChannelRow.css';
import VerifiedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { useStateValue } from './StateProvider';

function ChannelRow({ image, channel, verified, subs, noOfVideos, description }) {

  const [{ darkmode }] = useStateValue();

  return (
    <div className="channelRow">
      <Avatar className="channelRow-logo" alt={channel} src={image} />
      <div className={`channelRow-text ${darkmode && "channelRow-text-dark"}`}>
        <h4>{channel} {verified && <VerifiedIcon />}</h4>
        <p>
          {subs} subscibers
          <span className="channelRow-dot">
            &bull;
            </span>
          {noOfVideos} videos
        </p>
        <p>{description}</p>
      </div>
    </div>
  )
}

export default ChannelRow
