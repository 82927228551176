import React from 'react';
import { Avatar } from '@material-ui/core';
import { auth } from './firebase';
import './Popper.css';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Popover from '@material-ui/core/Popover';
import { useStateValue } from './StateProvider';
import HeaderOptions from './HeaderOptions';

function Popper({ userIsPresent, anchorEl, handleClose }) {

  const [{ user }] = useStateValue();
  const [{ darkmode }, dispatch] = useStateValue();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  const logout = (e) => {
    e.preventDefault();
    auth.signOut();
  }

  const toggleDarkMode = () => {
    darkmode ?
      dispatch({
        type: 'DARK_MODE_OFF',
      })
      :
      dispatch({
        type: 'DARK_MODE_ON',
      })
    handleClose()
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div className={`popover-options ${darkmode && "popover-options-dark"}`}>
        {userIsPresent ? (
          <>
            <div className="popover-options-userDetails">
              <Avatar className="popover-options-userDetails-avatar" alt={user.displayName} src={user.photoURL} />
              <div className={`popover-options-details ${darkmode && "popover-options-details-dark"}`}>
                <p style={{ fontWeight: "bold" }}>{user.displayName}</p>
                <p style={{ fontSize: "15px" }}>{user.email}</p>
                <p style={{ marginTop: "10px", fontSize: "13px", color: "#286ed8", fontWeight: "600", cursor: "pointer" }}>Manage your Google Account</p>
              </div>
            </div>
            <hr />
            <HeaderOptions icon="AccountBoxIcon" text="Your channel" right={false} />
            <HeaderOptions icon="MonetizationOnIcon" text="Purchases and Memberships" right={false} />
            <HeaderOptions icon="SettingsIcon" text="YouTube Studio" right={false} />
            <HeaderOptions icon="SupervisorAccountIcon" text="Switch Account" right />
            <div className="" onClick={logout}>
              <HeaderOptions icon="ExitToAppIcon" text="Sign Out" right={false} />
            </div>
            <hr />
          </>
        ) : (
            null
          )}

        <div onClick={toggleDarkMode} className="popover-optionsIcon">
          <Brightness4Icon className="popover-optionsIcon-item" />
          <p className={`popover-optionsIconText ${darkmode && "popover-optionsIconText-dark"}`}>
            Appearance: {darkmode ? "Dark" : "Light"}
          </p>
          <ArrowForwardIosIcon className={`popover-optionsIcon-itemArrow ${darkmode && "popover-icon-dark"}`} />
        </div>

        <HeaderOptions icon="TranslateIcon" text="Language: English" right />
        <HeaderOptions icon="LanguageIcon" text="Location: United States" right />
        <HeaderOptions icon="SettingsIcon" text="Settings" right={false} />
        <HeaderOptions icon="AssignmentIndIcon" text="Your data in YouTube" right={false} />
        <HeaderOptions icon="HelpIcon" text="Help" right={false} />
        <HeaderOptions icon="FeedbackIcon" text="Send Feedback" right={false} />
        <HeaderOptions icon="KeyboardIcon" text="Keyboard Shortcuts" right={false} />
        <hr />
        <div style={{ paddingBottom: "20px" }}>
          <HeaderOptions icon={null} text="Restricted Mode: Off" right />
        </div>

      </div>
    </Popover>
  )
}

export default Popper
