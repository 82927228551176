import React, { useEffect, useState } from 'react';
import './HeaderSearch.css';

import SearchIcon from '@material-ui/icons/Search';
import { IconButton } from '@material-ui/core';
import MicIcon from '@material-ui/icons/Mic';
import { useStateValue } from './StateProvider';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { auth } from './firebase';


function HeaderSearch() {
  const [{ search }] = useStateValue();
  const [{ darkmode }, dispatch] = useStateValue();
  const [inputSearch, setInputSearch] = useState('');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // User is logged in
        dispatch({
          type: 'SET_USER',
          user: authUser
        })
        console.log(authUser)
      }
      else {
        // User is not logged in
        dispatch({
          type: 'SET_USER',
          user: null
        })
      }
    })

    return () => {
      // cleanup
      unsubscribe();
    }
  }, [dispatch])

  const toggleSearch = () => {
    search ?
      dispatch({
        type: 'SEARCH_NOT_SHOW',
      })
      :
      dispatch({
        type: 'SEARCH_SHOW',
      })
  }

  return (
    <div className={`headerSearch ${darkmode && "headerSearch-dark"}`} >
      <div className="headerSearch-left">
        <ArrowBackIcon onClick={() => toggleSearch()} className={`${darkmode && "headerSearch-icon-dark"}`} />
      </div>

      <div className={`headerSearch-inputContainer ${darkmode && "headerSearch-inputContainer-dark"}`}>
        <div className={`headerSearch-input ${darkmode && "headerSearch-input-dark"}`}>
          <input value={inputSearch} onChange={e => setInputSearch(e.target.value)} placeholder="Search" type="text" />
          <div className="">
            <Link to={`/search/${inputSearch}`}>
              <IconButton className="headerSearch-input-searchLink-button" onClick={() => toggleSearch()}>
                <SearchIcon className={`headerSearch-searchInputButton ${darkmode && "headerSearch-searchInputButton-dark"}`} />
              </IconButton>
            </Link>
          </div>
        </div>
      </div>
      <div className="">
        <IconButton className="headerSearch-micInputButtonContainer">
          <MicIcon className={`headerSearch-micInputButton ${darkmode && "headerSearch-icon-dark headerSearch-micInputButton-dark"}`} />
        </IconButton>
      </div>
    </div>
  )
}

export default HeaderSearch
