import { Avatar } from '@material-ui/core';
import React from 'react';
import './VideoCard.css';
import { useStateValue } from './StateProvider';

function VideoCard({ image, title, channel, views, timestamp, channel_image }) {

  const [{ darkmode }] = useStateValue();

  return (
    <div className="videoCard">
      <img className="videoCard-image" src={image} alt={channel} />
      <div className="video-info">
        <Avatar className="videoCard-avatar" alt={channel} src={channel_image} />
        <div className={`videoCard-text ${darkmode && "videoCard-text-dark"}`}>
          <h4>{title}</h4>
          <p>{channel}</p>
          <p>
            {views}
            <span className="videoCard-dot">
              &bull;
            </span>
            {timestamp}
          </p>
        </div>
      </div>
    </div>
  )
}

export default VideoCard
