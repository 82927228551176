import React from 'react';
import './Sidebar.css';
import SidebarRow from './SidebarRow';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import HistoryIcon from '@material-ui/icons/History';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import SportsIcon from '@material-ui/icons/Sports';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import TheatersIcon from '@material-ui/icons/Theaters';
import DescriptionIcon from '@material-ui/icons/Description';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import FlareIcon from '@material-ui/icons/Flare';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import YouTubeIcon from '@material-ui/icons/YouTube';
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation';
import AddCircleOutlineTwoToneIcon from '@material-ui/icons/AddCircleOutlineTwoTone';
import SettingsIcon from '@material-ui/icons/Settings';
import FlagIcon from '@material-ui/icons/Flag';
import HelpIcon from '@material-ui/icons/Help';
import FeedbackIcon from '@material-ui/icons/Feedback';

function Sidebar() {
  return (
    <div className="sidebar">
      <SidebarRow selected={true} Icon={HomeIcon} title="Home" />
      <SidebarRow selected={false} Icon={WhatshotIcon} title="Trending" />
      <SidebarRow selected={false} Icon={SubscriptionsIcon} title="Subscription" />
      <hr />
      <SidebarRow selected={false} Icon={VideoLibraryIcon} title="Library" />
      <SidebarRow selected={false} Icon={HistoryIcon} title="History" />
      <SidebarRow selected={false} Icon={OndemandVideoIcon} title="Your videos" />
      <SidebarRow selected={false} Icon={WatchLaterIcon} title="Watch Later" />
      <SidebarRow selected={false} Icon={ThumbUpOutlinedIcon} title="Liked videos" />
      <SidebarRow selected={false} Icon={ExpandMoreOutlinedIcon} title="Show more" />
      <hr />
      <div className="sidebar-text">
        <p>BEST OF YOUTUBE</p>
      </div>
      <SidebarRow selected={false} Icon={MusicNoteIcon} title="Music" />
      <SidebarRow selected={false} Icon={SportsIcon} title="Sports" />
      <SidebarRow selected={false} Icon={SportsEsportsIcon} title="Gaming" />
      <SidebarRow selected={false} Icon={TheatersIcon} title="Movies & Shows" />
      <SidebarRow selected={false} Icon={DescriptionIcon} title="News" />
      <SidebarRow selected={false} Icon={GraphicEqIcon} title="Live" />
      <SidebarRow selected={false} Icon={FlareIcon} title="Fashion & Beauty" />
      <SidebarRow selected={false} Icon={EmojiObjectsIcon} title="Learning" />
      <SidebarRow selected={false} Icon={YouTubeIcon} title="Spotlight" />
      <SidebarRow selected={false} Icon={ThreeDRotationIcon} title="360 Video" />
      <hr />
      <SidebarRow selected={false} Icon={AddCircleOutlineTwoToneIcon} title="Browse Channel" />
      <hr />
      <div className="sidebar-text">
        <p>MORE FROM YOUTUBE</p>
      </div>
      <SidebarRow selected={false} Icon={YouTubeIcon} title="Youtube Premium" />
      <SidebarRow selected={false} Icon={GraphicEqIcon} title="Live" />
      <hr />
      <SidebarRow selected={false} Icon={SettingsIcon} title="Settings" />
      <SidebarRow selected={false} Icon={FlagIcon} title="Report History" />
      <SidebarRow selected={false} Icon={HelpIcon} title="Help" />
      <SidebarRow selected={false} Icon={FeedbackIcon} title="Send Feedback" />
      <hr />
      <div className="sidebar-linksSection">
        <p className="sidebar-links">
          <span className="sidebar-linkItems">About</span>
          <span className="sidebar-linkItems">Press</span>
          <span className="sidebar-linkItems">Copyright</span>
        </p>

        <p className="sidebar-links">
          <span className="sidebar-linkItems">Contact us</span>
          <span className="sidebar-linkItems">Creators</span>
        </p>

        <p className="sidebar-links">
          <span className="sidebar-linkItems">Advertise</span>
          <span className="sidebar-linkItems">Developers</span>
        </p>
      </div>

      <div className="sidebar-linksSection">
        <p className="sidebar-links">
          <span className="sidebar-linkItems">Terms</span>
          <span className="sidebar-linkItems">Privacy</span>
          <span className="sidebar-linkItems">Policy & Safety</span>
        </p>

        <p className="sidebar-links">
          <span className="sidebar-linkItems">How YouTube works</span>
        </p>

        <p className="sidebar-links">
          <span className="sidebar-linkItems">Test new features</span>
        </p>
      </div>
      <div className="sidebar-copyright">
        <p>&copy; 2021 Aarsh Oza</p>
      </div>
    </div>
  )
}

export default Sidebar
